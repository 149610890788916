var isFunction = function (fn) { return typeof fn === "function"; };
var doUnsubscribe = function (subscription) {
    subscription &&
        isFunction(subscription.unsubscribe) &&
        subscription.unsubscribe();
};
var doUnsubscribeIfArray = function (subscriptionsArray) {
    Array.isArray(subscriptionsArray) &&
        subscriptionsArray.forEach(doUnsubscribe);
};
export function AutoUnsubscribe(_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.blackList, blackList = _c === void 0 ? [] : _c, _d = _b.arrayName, arrayName = _d === void 0 ? "" : _d, _e = _b.event, event = _e === void 0 ? "ngOnDestroy" : _e;
    return function (constructor) {
        var original = constructor.prototype[event];
        if (!isFunction(original)) {
            throw new Error(constructor.name + " is using @AutoUnsubscribe but does not implement OnDestroy");
        }
        constructor.prototype[event] = function () {
            isFunction(original) && original.apply(this, arguments);
            if (arrayName) {
                doUnsubscribeIfArray(this[arrayName]);
                return;
            }
            for (var propName in this) {
                if (blackList.includes(propName))
                    continue;
                var property = this[propName];
                doUnsubscribe(property);
            }
        };
    };
}
